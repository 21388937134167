import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./components/login/Login";
import { useSelector } from "react-redux";
import { selectUser } from "./features/user/userSlice";
import Dashboard from "./components/dashboard/Dashboard";
import { useRegisterSessionMutation } from "./features/api/apiYeapGo";

function App() {
  const user = useSelector(selectUser);
  const [registerSession] = useRegisterSessionMutation();

  // Define una función que se ejecuta cuando el usuario intenta salir de la página
  window.onbeforeunload = function (e) {
    registerSession({
      Token: user.Token,
      body: {
        userId: user.id,
        startTime: user.lastActiveTime,
        endTime: Date.now(),
      },
    });
    // Previene la acción por defecto
    e.preventDefault();
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={user.Token ? <Dashboard /> : <Login />}
        ></Route>
        <Route path="login" element={<Login></Login>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
