import React from "react";
import swal from "sweetalert";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../features/user/userSlice";
import { useState } from "react";
import { selectAuth } from "../../features/user/userSlice";
import { Chips } from "primereact/chips";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutlined } from "@mui/icons-material";
import {
  Fab,
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  ListItem,
  Chip,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  useGetSpecialistsQuery,
  useUpdateSpecialistsMutation,
  useCreateSpecialistsMutation,
  useDeleteSpecialistsMutation,
  useGetUsersCalendarQuery,
  useGetAmbientesQuery,
} from "../../features/api/apiYeapGo";
import { AutoAwesomeMotion, Sensors, SensorWindow } from "@mui/icons-material";
import axios from "axios";
import AutocompleteSelect from "./Autocomplete";

export const CalendarSpecialists = () => {
  const Auth = useSelector(selectAuth);
  const { data, error, isLoading } = useGetSpecialistsQuery({ Token: Auth });
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [tags, setTags] = useState([]);
  const [updateSpecialist] = useUpdateSpecialistsMutation();
  const [createSpecialist] = useCreateSpecialistsMutation();
  const [deletSpecialist] = useDeleteSpecialistsMutation();
  const { data: ambientes } = useGetAmbientesQuery({ Token: Auth });
  const { data: userList } = useGetUsersCalendarQuery({ Token: Auth });
  let navigate = useNavigate();
  const [sensoresList, setSensoresList] = useState([]);
  const fetchData = async () => {
    try {
      const sensores = await axios.get(
        `${process.env.REACT_APP_API_URL}turnos/sensores2`
      );
      setSensoresList(sensores?.data);
    } catch (error) {
      console.error("Error al obtener los datos de la base de datos:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  React.useEffect(() => {
    let u = userList?.filter((us) => us.id === userId);
    if (u) setDataTag({ ...dataTag, user: u[0]?.id });
    else setDataTag({ ...dataTag, user: null });
  }, [userId]);

  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal(
        "Sin permisos suficientes",
        "Para gestionar Especialistas debe ser administrador",
        "error"
      );
      navigate("/");
    }
  }, [user]);

  const [dataTag, setDataTag] = useState({
    id: "",
    nombre: "",
    sensores: [],
  });

  const handleClose = (action) => {
    setOpen(false);
    setDataTag({
      id: "",
      nombre: "",
      sensores: [],
    });
    setTags([]);
    setUserId(0);
  };

  /* table columns */
  const columns = [
    {
      width: 20,
      sortable: false,
      renderCell: (params) => {
        const eliminarSensor = (e) => {
          e.stopPropagation();
          deletSpecialist({ Token: Auth, id: params.row.id })
            .unwrap()
            .then(() => {
              swal("Especialista eliminado correctamente", "", "success");
            });
        };
        return (
          <Button
            variant="text"
            color="secondary"
            startIcon={<DeleteOutlined />}
            onClick={eliminarSensor}
          ></Button>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 200,
    },
    {
      field: "nombreUsuario",
      headerName: "Usuario asignado",
      width: 200,
    },
    {
      field: "ambiente",
      headerName: "Ambiente",
      width: 400,
    },
    {
      field: "area",
      headerName: "Área",
      width: 200,
    },

    {
      field: "sensores",
      headerName: "Sensores",
      width: 1100,
    },
  ];

  const gestionar = () => {
    if (
      dataTag.nombre === "" ||
      dataTag.area === "" ||
      dataTag.sensores === ""
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      if (dataTag?.id === "" || !dataTag.id) {
        console.log(dataTag);
        createSpecialist({
          Token: Auth,
          body: {
            ...dataTag,
            sensores: dataTag.sensores.join(","),
            id_user: userId || 0,
          },
        })
          .unwrap()
          .then(() => {
            swal("Especialista creado correctamente", "", "success");
            setOpen(false);
            /* Funcion para borrar */
            setDataTag({
              id: "",
              nombre: "",
              area: "",
              sensores: [],
            });
            setTags([]);
            setUserId(0);
          })
          .catch((e) => {
            swal("No se pudo crear el especialista", "", "error");
          });
      } else {
        updateSpecialist({
          Token: Auth,
          id: dataTag.id,
          body: {
            ...dataTag,
            sensores: dataTag.sensores.join(","),
            id_user: userId || 0,
          },
        })
          .unwrap()
          .then(() => {
            swal("especialista modificado correctamente", "", "success");
            setOpen(false);
            /* Funcion para borrar */
            setDataTag({
              id: "",
              nombre: "",
              area: "",
              sensores: [],
            });
            setUserId(0);
            setTags([]);
          })
          .catch((e) => {
            swal("No se pudo modificar el especialista", "", "error");
          });
      }
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          height: 300,
          width: "100%",
        }}
      >
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <DataGrid
              density={"compact"}
              autoHeight
              rows={data || []}
              pageSize={12}
              columns={columns}
              onRowClick={(e) => {
                setOpen(true);
                setDataTag({
                  ...e.row,
                  id: e.row.id,
                  nombre: e.row.nombre,
                  area: e.row.area,
                  sensores: e.row.sensores.split(","),
                });
                setUserId(e.row.id_user);
              }}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.id}
            />
          </>
        )}
      </Grid>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="gestionar"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          margin: 0,
          right: 20,
          top: 100,
          left: "auto",
          position: "fixed",
        }}
      >
        Agregar Especialista
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle>
          <DialogTitle id="scroll-dialog-title">
            GESTIÓN DE ESPECIALISTA
          </DialogTitle>
        </DialogTitle>
        <DialogContent style={{ cursor: "pointer" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2%",
              }}
            >
              <TextField
                fullWidth
                required
                value={dataTag.nombre}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    nombre: e.target.value,
                  })
                }
                label="nombre"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesomeMotion />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2%",
              }}
            >
              <TextField
                fullWidth
                required
                value={dataTag.area}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    area: e.target.value,
                  })
                }
                label="Área"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesomeMotion />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                variant="outlined"
                label="Sensores"
                SelectProps={{
                  multiple: true,
                }}
                value={dataTag?.sensores ?? []}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    sensores: e.target.value,
                  })
                }
              >
                {sensoresList?.map((sensor) => {
                  return (
                    <MenuItem
                      value={sensor.SEN_Sensor}
                      key={sensor.SEN_Sensor}
                    >
                      {sensor.SEN_Sensor}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              {userList ? (
                <AutocompleteSelect
                  options={userList
                    ?.filter((u) => u.activo === 1)
                    .map((u) => {
                      return { id: u.id, completename: u.nombre };
                    })}
                  data={userId}
                  setData={setUserId}
                  label={"Usuario asignado"}
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              {ambientes ? (
                <AutocompleteSelect
                  options={ambientes?.map(({ ambiente }) => {
                    return { id: ambiente, completename: ambiente };
                  })}
                  data={dataTag.ambiente}
                  setData={(am) => {
                    setDataTag({ ...dataTag, ambiente: am });
                  }}
                  label={"Ambiente"}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => gestionar()}
          >
            Aceptar
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
