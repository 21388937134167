import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import axios from "axios";
import Swal from "sweetalert2";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  Fab,
  MenuItem,
} from "@mui/material";
import AutocompleteSelect from "./Autocomplete";
import { useGetSpecialistsQuery } from "../../features/api/apiYeapGo";

const Calendar = () => {
  const [databaseEvents, setDatabaseEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogEvent, setDialogEvent] = useState(false);
  const [eventSelected, setEventS] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [repeatEvent, setRepeatEvent] = useState(false);
  const [repeatStartDate, setRepeatStartDate] = useState("");
  const [repeatEndDate, setRepeatEndDate] = useState("");
  const [userId, setUserId] = useState();
  const [users, setUsers] = useState();
  const [userToFill, setUserToFill] = useState();
  const [sensoresList, setSensoresList] = useState([]);
  const calendarRef = useRef(null);
  const { data: specialistList, error, isLoading } = useGetSpecialistsQuery({});
  useEffect(() => {
    fetchData(); // Realizar la primera llamada al cargar el componente

    const interval = setInterval(fetchData, 5000); // Realizar la llamada cada 5 segundos

    return () => {
      clearInterval(interval); // Limpiar el intervalo al desmontar el componente
    };
  }, []);
  useEffect(() => {
    let u = specialistList?.filter((us) => us.id === userId);
    if (u) setUserToFill(u[0]);
    else setUserToFill({});
  }, [userId]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}turnos`
      );
      console.log(response?.data);
      setDatabaseEvents(response?.data);

      const sensores = await axios.get(
        `${process.env.REACT_APP_API_URL}turnos/sensores`
      );
      setSensoresList(sensores?.data);
      const userlist = await axios.get(
        `${process.env.REACT_APP_API_URL}turnos/users`
      );

      setUsers(userlist?.data);
    } catch (error) {
      console.error("Error al obtener los datos de la base de datos:", error);
    }
  };
  useEffect(() => {
    if (databaseEvents) {
      setCalendarEvents(convertToCalendarEvents(databaseEvents));
    }
    // Actualizar el arreglo de eventos del calendario cuando cambie el arreglo de la base de datos
  }, [databaseEvents]);
  const createEvent = async (eve) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}turnos`, {
        ...eve,
        ...userToFill,
        especialista_id: userId,
      });
      let turnos = await axios.get(`${process.env.REACT_APP_API_URL}turnos`);
      setDatabaseEvents(turnos?.data);
      Swal.fire(
        "Turno creado",
        "El turno ha sido creado correctamente",
        "success"
      );
      setUserToFill({});
    } catch (error) {
      Swal.fire(
        "Error al crear el turno",
        "El turno no ha podido crear eliminado",
        "error"
      );
    }
  };
  const deleteEvent = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}turnos/${id}`,
            {}
          );
          Swal.fire(
            "Turno eliminado",
            "El turno ha sido eliminado correctamente",
            "success"
          );
          let turnos = await axios.get(
            `${process.env.REACT_APP_API_URL}turnos`
          );
          setDatabaseEvents(turnos?.data);
        } catch (error) {
          Swal.fire(
            "Error al eliminar el turno",
            "El turno no ha podido ser eliminado",
            "error"
          );
        }
      }
    });
  };
  const addDay = (d, days) => {
    try {
      const opDate = new Date(d);
      opDate.setDate(opDate.getDate() + days);
      return opDate.toISOString().slice(0, 10);
    } catch (error) {
      return null;
    }
  };
  const updateEvent = async (id) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}turnos/${id}`, {
        fecha_fin: eventSelected.all_day
          ? eventSelected.end_date
          : eventSelected.repeat_end_date,
        fecha_inicio: eventSelected.all_day
          ? eventSelected.start_date
          : eventSelected.repeat_start_date,
        allDay: eventSelected.all_day,
      });
      Swal.fire(
        "Turno modificado",
        "El turno ha sido modificado correctamente",
        "success"
      );
      let turnos = await axios.get(`${process.env.REACT_APP_API_URL}turnos`);
      setDatabaseEvents(turnos?.data);
    } catch (error) {
      Swal.fire(
        "Error al modificar el turno",
        "El turno no ha podido ser modificado",
        "error"
      );
    }
  };
  const convertToCalendarEvents = (events) => {
    // Convertir los eventos de la base de datos a un formato compatible con el calendario
    const calendarEvents = [];

    events.forEach((event) => {
      if (event.repeat && event.repeat_start_date && event.repeat_end_date) {
        const repeatStart = new Date(event.repeat_start_date);
        const repeatEnd = new Date(event.repeat_end_date);

        let currentDate = repeatStart;

        while (currentDate <= repeatEnd) {
          const newEvent = {
            title: event.title,
            start:
              currentDate.toISOString().slice(0, 10) +
              (event.all_day ? "" : "T" + event.start_time),
            end:
              currentDate.toISOString().slice(0, 10) +
              (event.all_day ? "" : "T" + event.end_time),
            allDay: event.all_day,
            color: "#8554d1",
            extendedProps: {
              id: event.id,
              nombre: event.nombre,
              correo: event.correo,
              telefono: event.telefono,
              ambiente: event.ambiente,
              area: event.area,
            },
          };

          calendarEvents.push(newEvent);

          const nextDay = currentDate.getDate() + 1;
          currentDate.setDate(nextDay);
        }
      } else {
        const calendarEvent = {
          title: event.title,
          start:
            event.start_date + (event.all_day ? "" : "T" + event.start_time),
          end: event.end_date + (event.all_day ? "" : "T" + event.end_time),
          allDay: event.all_day,
          color: "purple",
          extendedProps: {
            id: event.id,
            nombre: event.nombre,
            ambiente: event.ambiente,
            correo: event.correo,
            telefono: event.telefono,
            area: event.area,
          },
        };

        calendarEvents.push(calendarEvent);
      }
    });

    return calendarEvents;
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedDate(selectInfo);
    setUserId();
    setStartDay(selectInfo.startStr.slice(0, 10));

    // Ajustar la fecha final al día siguiente
    const endDate = new Date(selectInfo.endStr);
    endDate.setDate(endDate.getDate() - 1);
    setEndDay(endDate.toISOString().slice(0, 10));

    setStartTime(selectInfo.startStr.slice(11, 16));
    setEndTime(selectInfo.endStr.slice(11, 16));

    setRepeatStartDate(selectInfo.startStr.slice(0, 10));
    setRepeatEndDate(selectInfo.startStr.slice(0, 10));

    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setUserToFill({});
  };

  const handleDialogSave = () => {
    if ((userId || userToFill) && selectedDate.allDay === true) {
      // Ajustar la fecha final al día siguiente
      const endDate = new Date(endDay);
      endDate.setDate(endDate.getDate() + 1);

      const newEvent = {
        start_date: startDay,
        end_date: endDate.toISOString().slice(0, 10),
        start_time: "",
        end_time: "",
        all_day: true,
        repeat: false,
        repeat_start_date: "",
        repeat_end_date: "",
      };
      createEvent(newEvent);
      setDialogOpen(false);
    } else if ((userId || userToFill) && startTime && endTime) {
      const newEvent = {
        start_date: selectedDate.startStr.slice(0, 10),
        end_date: selectedDate.startStr.slice(0, 10),
        start_time: startTime,
        end_time: endTime,
        all_day: false,
        repeat: repeatEvent,
        repeat_start_date: repeatStartDate,
        repeat_end_date: repeatEndDate,
      };

      if (repeatEvent && repeatStartDate && repeatEndDate) {
        const repeatStart = new Date(repeatStartDate);
        const repeatEnd = new Date(repeatEndDate);

        // Ajustar la fecha final al día siguiente
        const endDate = new Date(endDay);
        endDate.setDate(endDate.getDate() + 1);

        // Crear el evento repetido con las fechas de inicio y fin ajustadas
        const repeatedEvent = {
          ...newEvent,
          start_date: repeatStart.toISOString().slice(0, 10),
          end_date: endDate.toISOString().slice(0, 10),
        };

        createEvent(newEvent);
      } else {
        createEvent(newEvent);
      }

      setDialogOpen(false);
    }
  };

  const eventContent = (eventInfo) => {
    return (
      <>
        {eventInfo.event.extendedProps.nombre ? (
          <>
            <b>{eventInfo.timeText}</b>
            <ul>
              <li>
                <b>{eventInfo.event.title}</b>
              </li>
              <li>{eventInfo.event.extendedProps.nombre}</li>
              <li>{eventInfo.event.extendedProps.ambiente}</li>
              <li>{eventInfo.event.extendedProps.correo}</li>
              <li>{eventInfo.event.extendedProps.telefono}</li>{" "}
              <li>Inicio:{eventInfo.event.start.toISOString()}</li>
              <li>Fin:{eventInfo.event.end.toISOString()}</li>
            </ul>
          </>
        ) : (
          <>
            <ul>
              <li>{eventInfo.event.title}</li>
              <li>No se ha asignado ningun usuario</li>
            </ul>
          </>
        )}
      </>
    );
  };

  const changeView = (view) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(view);
    }
  };

  const handleEventClick = (eventInfo) => {
    setEventS(
      databaseEvents.filter((e) => e.id === eventInfo.event.extendedProps.id)[0]
    );
    setDialogEvent(true);
  };

  return (
    <div>
      {" "}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="volver"
        style={{
          margin: 0,
          top: "auto",
          right: 90,
          bottom: 45,
          left: "auto",
          position: "fixed",
          zIndex: 9999,
        }}
        onClick={() => changeView("timeGridWeek")}
      >
        Semana
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="volver"
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 45,
          left: "auto",
          position: "fixed",
          zIndex: 9999,
        }}
        onClick={() => changeView("dayGridMonth")}
      >
        Mes
      </Fab>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        weekends={false}
        events={calendarEvents}
        eventContent={eventContent}
        locale={esLocale}
        selectable={true}
        select={handleDateSelect}
        slotMinTime="00:00:00"
        slotMaxTime="24:00:00"
        eventClick={handleEventClick}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Crear evento</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mt={2}>
            {specialistList ? (
              <AutocompleteSelect
                options={specialistList?.map((s) => {
                  return { id: s.id, completename: s.nombre };
                })}
                data={userId}
                setData={setUserId}
                label={"Especialista asignado"}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              fullWidth
              focused
              label="Area"
              value={userToFill?.area}
            />
          </Box>

          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              fullWidth
              label="Sensores"
              value={userToFill?.sensores}
              focused
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              fullWidth
              label="Usuario asignado"
              value={userToFill?.nombreUsuario || "No hay usuario asignado"}
              focused
            />
          </Box>

          {selectedDate?.allDay ? (
            <Box display="flex" alignItems="center" mt={2}>
              <TextField
                label="Día de inicio"
                type="date"
                value={startDay}
                onChange={(e) => setStartDay(e.target.value)}
              />
              <Box mx={2}>-</Box>
              <TextField
                label="Día final"
                type="date"
                value={endDay}
                onChange={(e) => setEndDay(e.target.value)}
              />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" mt={2}>
              <TextField
                label="Hora de inicio"
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
              <Box mx={2}>-</Box>
              <TextField
                label="Hora final"
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </Box>
          )}
          {selectedDate?.allDay ? (
            <></>
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={repeatEvent}
                    onChange={(e) => setRepeatEvent(e.target.checked)}
                  />
                }
                label="Repetir evento"
              />
              {repeatEvent && (
                <Box display="flex" alignItems="center">
                  <TextField
                    label="Fecha de inicio"
                    type="date"
                    value={repeatStartDate}
                    onChange={(e) => setRepeatStartDate(e.target.value)}
                  />
                  <Box mx={2}>-</Box>
                  <TextField
                    label="Fecha final"
                    type="date"
                    value={repeatEndDate}
                    onChange={(e) => setRepeatEndDate(e.target.value)}
                  />
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogEvent}
        onClose={() => {
          setDialogEvent(false);
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{eventSelected?.title}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Nombre"
              value={eventSelected?.nombre}
              disabled
              fullWidth
            />
          </Box>{" "}
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Correo"
              value={eventSelected?.correo}
              disabled
              fullWidth
            />
          </Box>{" "}
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Teléfono"
              value={eventSelected?.telefono}
              disabled
              fullWidth
            />
          </Box>{" "}
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Fecha de inicio"
              type="date"
              value={eventSelected?.start_date}
              disabled
              fullWidth
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Fecha de fin"
              type="date"
              disabled={!(eventSelected?.repeat || eventSelected?.all_day)}
              value={
                eventSelected?.repeat
                  ? eventSelected?.repeat_end_date
                  : addDay(eventSelected?.end_date, -1)
              }
              onChange={(e) => {
                setEventS({
                  ...eventSelected,
                  repeat_end_date: e.target.value,
                  end_date: addDay(e.target.value, 1),
                });
              }}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogEvent(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteEvent(eventSelected.id);
              setDialogEvent(false);
            }}
            variant="warning"
          >
            Eliminar
          </Button>
          {eventSelected?.repeat || eventSelected?.all_day ? (
            <Button
              onClick={() => {
                if (
                  eventSelected.repeat_start_date >
                    eventSelected.repeat_end_date ||
                  eventSelected.start_date > eventSelected.end_date
                ) {
                  Swal.fire(
                    "La fecha de inicio no puede ser mayor a la ficha final",
                    "",
                    "error"
                  );
                } else {
                  updateEvent(eventSelected.id);
                  setDialogEvent(false);
                }
              }}
              variant="primary"
            >
              Cambiar fecha fin
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Calendar;
