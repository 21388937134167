import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useGetTipoEventoStatisticsQuery } from "../../features/api/apiYeapGo";
import { selectUser } from "../../features/user/userSlice";
import Moment from "moment";
import { useEffect, useState } from "react";

export const STTipoEvento = ({ dateRange, tipoTicket }) => {
  const { Token } = useSelector(selectUser);
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const { data, isLoading } = useGetTipoEventoStatisticsQuery({
    Token,
    ...date,
    TipoTicket: tipoTicket,
  });
  useEffect(() => {
    if (dateRange?.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);
  const state = {
    series: [
      {
        name: "Número de gestionados",
        data: data?.map((d) => d.Gestionados) || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,

        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      legend: {
        show: false,
      },

      xaxis: {
        categories: data?.map((d) => d.tipoEvento) || [],
      },
    },
  };

  return (
    <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Estadisticas de gestión por tipo de evento
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : data.length > 0 ? (
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={"340rem"}
          />
        ) : (
          <>No hay datos disponibles</>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
