/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { MenuItem } from "@mui/material";
import {
  useGetAnalistasQuery,
  useUpdateEventMutation,
} from "../../features/api/apiYeapGo";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import swal from "sweetalert";
import { useSnackbar } from "notistack";
export const DialogEvents = ({ open, setOpen, eventsID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    EVE_SD_Area: "",
    EVE_SD_AnalistaContactado: "",
    EVE_TipoEvento: "",
    EVE_SD_TicketCreado: "",
  });
  const { Username, Token } = useSelector(selectUser);
  const [updateEvent] = useUpdateEventMutation();
  const { data: dataAnalistas, isLoading: isLoadingAnalistas } =
    useGetAnalistasQuery({ Token });

  const gestionar = () => {
    if (!eventsID || eventsID.length === 0) {
      setOpen(false);
      swal("Debe seleccionar al menos un evento", "", "error");
      return;
    }
    if (
      !(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        data.EVE_IPFallo
      ) || !data.EVE_IPFallo) ||
      !data.EVE_SD_Area ||
      !data.EVE_SD_AnalistaContactado ||
      !data.EVE_TipoEvento ||
      (!(data.EVE_SD_TicketCreado ?? "") &&
        data.EVE_TipoEvento !== "Informativo")
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      for (let index = 0; index < eventsID.length; index++) {
        updateEvent({
          Token,
          id: eventsID[index],
          body: {
            ...data,
            EVE_Operador: Username,
            EVE_SD_TicketCreado:
              data.EVE_TipoEvento === "Informativo"
                ? "INFORMATIONAL"
                : data.EVE_SD_TicketCreado,
          },
        })
          .unwrap()
          .then(() => {
            enqueueSnackbar(
              `Evento ${eventsID[index]} gestionado correctamente`,
              { variant: "success" }
            );
          })
          .catch((err) => {
            enqueueSnackbar(
              `Evento ${eventsID[index]} no pudo ser gestionado`,
              { variant: "error" }
            );
          });
      }
      setData({
        EVE_SD_Area: "",
        EVE_SD_AnalistaContactado: "",
        EVE_TipoEvento: "",
        EVE_SD_TicketCreado: "",
      });
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tipoEvento = [
    {
      value: "Ticket",
      label: "Ticket",
    },
    {
      value: "CCT",
      label: "CCT",
    },
    {
      value: "Informativo",
      label: "Informativo",
    },
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <DialogTitle>Gestionar masivamente</DialogTitle>
      <DialogContentText></DialogContentText>
      <DialogContent>
        <TextField
          size="small"
          required
          select
          fullWidth
          defaultValue=""
          label="EVE_SD_Area"
          onChange={(e) =>
            setData({
              ...data,
              EVE_SD_Area: e.target.value,
            })
          }
        >
          {[...new Set(dataAnalistas?.map((a) => a.area_analista_app))].map(
            (el) => (
              <MenuItem value={el} key={el}>
                {el}
              </MenuItem>
            )
          )}
        </TextField>
      </DialogContent>
      <DialogContent>
        <TextField
          size="small"
          select
          required
          fullWidth
          defaultValue=""
          label="EVE_SD_AnalistaContactado"
          onChange={(e) =>
            setData({
              ...data,
              EVE_SD_AnalistaContactado: e.target.value,
            })
          }
        >
          {isLoadingAnalistas ? (
            <MenuItem></MenuItem>
          ) : (
            dataAnalistas
              .filter((a) =>
                a.area_analista_app.includes(data.EVE_SD_Area ?? "")
              )
              .map((item, index) => {
                return (
                  <MenuItem key={index} value={item.nombre_analista_app}>
                    {item.nombre_analista_app}
                  </MenuItem>
                );
              })
          )}
        </TextField>
      </DialogContent>
      <DialogContent>
        <TextField
          required
          size="small"
          fullWidth
          select
          value={data.EVE_TipoEvento || ""}
          label="EVE_TipoEvento"
          onChange={(e) =>
            setData({
              ...data,
              EVE_TipoEvento: e.target.value,
            })
          }
        >
          {tipoEvento.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
      </DialogContent>
      {data.EVE_TipoEvento !== "Informativo" ? (
        <DialogContent>
          <TextField
            required
            id="standard-number"
            type="number"
            size="small"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={data.EVE_SD_TicketCreado || ""}
            label="EVE_SD_TicketCreado"
            onChange={(e) =>
              setData({
                ...data,
                EVE_SD_TicketCreado: e.target.value,
              })
            }
          />
        </DialogContent>
      ) : (
        <></>
      )}
      <DialogContent>
        <TextField
          size="small"
          fullWidth
          value={data.EVE_IPFallo || ""}
          label="EVE_IPFallo"
          error={
            !!(
              data.EVE_IPFallo &&
              !/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                data.EVE_IPFallo
              )
            )
          }
          helperText={
            data.EVE_IPFallo &&
            !/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              data.EVE_IPFallo
            )
              ? "Ingrese una dirección IP válida"
              : ""
          }
          onChange={(e) =>
            setData({
              ...data,
              EVE_IPFallo: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={gestionar}>Gestionar</Button>
      </DialogActions>
    </Dialog>
  );
};
