import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { STAgregados } from "./STAgregados";
import { STDesagregados } from "./STDesagregados";
import { DateRangePicker } from "materialui-daterange-picker";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import swal from "sweetalert";
import { STAgregadosMonth } from "./STAgregadosMonth";
import { STDesagregadosMonth } from "./STDesagregadosMonth";

export const NodesReports = () => {
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [ambiente, setAmbiente] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openA = Boolean(anchorEl);
  const [dateRange, setDateRange] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal("Sin permisos suficientes", "", "error");
      navigate("/");
    }
  }, [user]);
  const toggle = () => setOpen(!open);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="start"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <STAgregados dateRange={dateRange} ambiente={ambiente}></STAgregados>
      </Grid>
      <Grid item xs={12} md={6}>
        <STDesagregados
          dateRange={dateRange}
          ambiente={ambiente}
        ></STDesagregados>
      </Grid>
      <Grid item xs={12} md={6}>
        <STAgregadosMonth
          dateRange={dateRange}
          ambiente={ambiente}
        ></STAgregadosMonth>
      </Grid>
      <Grid item xs={12} md={6}>
        <STDesagregadosMonth
          dateRange={dateRange}
          ambiente={ambiente}
        ></STDesagregadosMonth>
      </Grid>
      {/* <Grid item xs={12}>
        <STPlatform dateRange={dateRange}></STPlatform>
      </Grid> */}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="volver"
        onClick={toggle}
        style={{
          margin: 0,
          top: "auto",
          right: 160,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        Establecer rango de fecha
      </Fab>
      <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Selecciona el rango de fecha"}
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>Cerrar</Button>
        </DialogActions>
      </Dialog>{" "}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Tipo ticket"
        onClick={handleClick}
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        Ambiente
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openA}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setAmbiente("");
          }}
        >
          Todos los ambientes
        </MenuItem>
        {[
          "APLICACIONES (BASES DE DATOS SQL Server TP)",
          "NETWORKING TPC",
          "SYSTEM TP",
          "RECORDINGS TP",
          "ACD TP",
          "APLICACIONES TPC",
          "HELPDESK",
          "INFRAESTRUCTURA TP",
        ].map((e) => (
          <MenuItem
            key={e}
            onClick={() => {
              handleClose();
              setAmbiente(e);
            }}
          >
            {e}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};
