import { DateRangePicker } from "materialui-daterange-picker";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetEventsReportQuery } from "../../features/api/apiYeapGo";
import { selectAuth } from "../../features/user/userSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Moment from "moment";
import * as XLSX from "xlsx";
import { useEffect } from "react";
import moment from "moment";
import { Download } from "@mui/icons-material";

export const EventsReport = () => {
  const Auth = useSelector(selectAuth);
  const [dateRange, setDateRange] = useState({});
  const [dataQ, setDataQ] = useState({
    fechaInicial: Moment(moment().month(moment().month() - 1)).format(
      "YYYY-MM-DD"
    ),
    fechaFinal: Moment(moment()).format("YYYY-MM-DD"),
    Nodo: "",
    Ambiente: "",
    Caption: "",
  });
  const { data, error, isLoading } = useGetEventsReportQuery(
    { ...dataQ, Token: Auth },
    {
      pollingInterval: 1000 * 60 * 5,
    }
  );
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (dateRange.startDate) {
      setDataQ({
        ...dataQ,
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);

  const toggle = () => setOpen(!open);
  const columns = [
    {
      field: "EVE_ID_ActiveAlert",
      headerName: "EVE_ID_ActiveAlert",
      width: 160,
    },

    {
      field: "EVE_FechaRegistro",
      headerName: "EVE_FechaRegistro",
      width: 160,
    },
    {
      field: "EVE_FechaHoraAlerta",
      headerName: "EVE_FechaHoraAlerta",
      width: 160,
    },
    {
      field: "EVE_DescripcionAlarma",
      headerName: "EVE_DescripcionAlarma",
      width: 600,
    },
    {
      field: "EVE_MensajeAlarma",
      headerName: "EVE_MensajeAlarma",
      width: 1000,
    },
    {
      field: "EVE_Severidad",
      headerName: "EVE_Severidad",
      width: 170,
    },
    {
      field: "EVE_TipoEvento",
      width: 170,
      headerName: "EVE_TipoEvento",
    },
    {
      field: "EVE_Clasification",
      headerName: "EVE_Clasification",
      width: 170,
    },
    {
      field: "EVE_TipoSensor",
      headerName: "EVE_TipoSensor",
      width: 170,
    },

    {
      field: "EVE_Sensor",
      headerName: "EVE_Sensor",
      width: 250,
    },
    {
      field: "EVE_IPNodo",
      headerName: "EVE_IPNodo",
      width: 170,
    },
    {
      field: "EVE_Caption",
      headerName: "EVE_Caption",
      width: 500,
    },
    {
      field: "EVE_MachineType",
      headerName: "EVE_MachineType",
      width: 270,
    },
    {
      field: "EVE_Ubicacion",
      headerName: "EVE_Ubicacion",
      width: 170,
    },
    {
      field: "EVE_ORN_EntityCaption",
      headerName: "EVE_ORN_EntityCaption",
      width: 170,
    },

    {
      field: "EVE_ORN_Ambiente",
      headerName: "EVE_ORN_Ambiente",
      width: 200,
    },
    {
      field: "EVE_SD_TicketCreado",
      headerName: "EVE_SD_TicketCreado",
      width: 200,
    },
    {
      field: "EVE_SD_Area",
      headerName: "EVE_SD_Area",
      width: 150,
    },
    {
      field: "EVE_SD_EstadoTicket",
      headerName: "EVE_SD_EstadoTicket",
      width: 220,
    },
    {
      field: "EVE_SD_AnalistaContactado",
      headerName: "EVE_SD_AnalistaContactado",
      width: 220,
    },
    {
      field: "EVE_Operador",
      headerName: "EVE_Operador",
      width: 150,
    },
    {
      field: "EVE_Gestionado",
      headerName: "EVE_Gestionado",
      width: 180,
    },
    {
      field: "EVE_TipoGestion",
      headerName: "EVE_Tipo_de_Impacto",
      width: 280,
    },
    {
      field: "EVE_UltimoCaso_Note",
      headerName: "EVE_UltimoCaso_Note",
      width: 150,
    },
    {
      field: "EVE_FechaModificacion",
      headerName: "EVE_FechaModificacion",
      width: 270,
    },
    {
      field: "ROL",
      headerName: "ROL",
      width: 220,
    },
    {
      field: "TIPO_DE_SERVICIO",
      headerName: "TIPO_DE_SERVICIO",
      width: 220,
    },
  ];

  /* Dowload Report */
  const downloadReport = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `Reporte ${dataQ.fechaInicial} - ${dataQ.fechaFinal}`
    );
    XLSX.writeFile(
      wb,
      `Reporte ${dataQ.fechaInicial} - ${dataQ.fechaFinal}.xlsx`
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={toggle}
          >
            Rango de fechas
          </Button>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={dataQ.Nodo}
            label="Nodo"
            placeholder="Ingrese un valor de Nodo"
            color="secondary"
            onChange={(e) => {
              setDataQ({ ...dataQ, Nodo: e.target.value });
            }}
            focused
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={dataQ.Ambiente}
            label="Ambiente"
            placeholder="Ingrese un valor de Ambiente"
            color="secondary"
            onChange={(e) => {
              setDataQ({ ...dataQ, Ambiente: e.target.value });
            }}
            focused
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={dataQ.Caption}
            label="Caption"
            color="secondary"
            placeholder="Ingrese un valor de caption"
            onChange={(e) => {
              setDataQ({ ...dataQ, Caption: e.target.value });
            }}
            focused
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <DataGrid
                autoHeight
                getRowHeight={() => "auto"}
                rows={data || []}
                pageSize={25}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                getRowId={(row) => row.EVE_ID_ActiveAlert}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Selecciona el rango de fecha"}
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Descargar reporte"
        style={{
          margin: 0,
          top: "auto",
          left: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
        onClick={downloadReport}
      >
        <Download sx={{ mr: 1 }} />
        Descargar reporte
      </Fab>
    </>
  );
};
