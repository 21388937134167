import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useGetSessionsQuery } from "../../features/api/apiYeapGo";
import Moment from "moment";
import { selectUser } from "../../features/user/userSlice";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
const horario = [
  { label: "6am - 2pm", horaInicio: "6", horaFin: "14" },
  { label: "2pm -10pm", horaInicio: "14", horaFin: "22" },
  { label: "10pm - 6am", horaInicio: "22", horaFin: "6" },
];
const columns = [
  // { field: "id_usuario", headerName: "ID", width: 70 },
  { field: "nombre_usuario", headerName: "Nombre Usuario", width: 200 },
  { field: "inicio_bloque", headerName: "Inicio Bloque", width: 200 },
  { field: "fin_bloque", headerName: "Fin Bloque", width: 200 },
  // { field: "num_sesiones", headerName: "Número de Sesiones", width: 200 },
  {
    field: "minutos_trabajados",
    headerName: "Minutos de actividad",
    width: 200,
  },
  {
    field: "tiempo_muerto",
    headerName: "Tiempo muerto",
    width: 200,
  },
  {
    field: "eventos_gestionados",
    headerName: "Eventos gestionados",
    width: 150,
  },
  {
    field: "eventos_gestionados_hora",
    headerName: "Promedio por hora",
    width: 150,
  },
  {
    field: "promedio_tiempo_evento",
    headerName: "Tiempo promedio por evento",
    width: 250,
  },
];

export const STSessions = ({ dateRange }) => {
  const { Token } = useSelector(selectUser);
  const [sessions, setSessions] = useState([]);
  const [analist, setAnalist] = useState("");
  const [horarioF, setHorarioF] = useState("");
  const [analists, setAnalists] = useState([]);
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const { data } = useGetSessionsQuery({
    Token,
    ...date,
  });
  useEffect(() => {
    if (dateRange?.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);
  useEffect(() => {
    if (data) {
      setAnalists([...new Set(data?.map((a) => a.nombre_usuario))]);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      let filteredSessions = data.filter((d) => {
        if (horarioF) {
          const selectedHorario = horario.find((h) => h.label === horarioF);
          const horaInicio = parseInt(selectedHorario.horaInicio);
          const horaFin = parseInt(selectedHorario.horaFin);
          const inicio = moment(
            d.inicio_bloque,
            "DD/MM/YYYY, h:mm:ss a"
          ).toDate();
          const fin = moment(d.fin_bloque, "DD/MM/YYYY, h:mm:ss a").toDate();

          if (horaInicio < horaFin) {
            return (
              inicio.getHours() >= horaInicio &&
              fin.getHours() < horaFin &&
              d?.nombre_usuario.includes(analist)
            );
          } else {
            return (
              d?.nombre_usuario.includes(analist) &&
              (inicio.getHours() >= horaInicio || fin.getHours() < horaFin)
            );
          }
        } else {
          return d?.nombre_usuario.includes(analist);
        }
      });

      setSessions(filteredSessions);
    }
  }, [data, horarioF, analist]);

  return (
    <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Sesiones del usuario
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} md={5} lg={5}>
            <TextField
              select
              fullWidth
              defaultValue=""
              label="Analista"
              onChange={(e) => setAnalist(e.target.value)}
            >
              <MenuItem value="" key="0">
                {"Todos los analistas"}
              </MenuItem>
              {analists.map((el) => (
                <MenuItem value={el} key={el}>
                  {el}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={0} md={2} lg={2}></Grid>
          <Grid item xs={12} md={5} lg={5}>
            <TextField
              select
              fullWidth
              defaultValue=""
              label="Horario"
              onChange={(e) => setHorarioF(e.target.value)}
            >
              <MenuItem value="" key="0">
                {"Todos los horarios"}
              </MenuItem>
              {horario.map((el) => (
                <MenuItem value={el.label} key={el.label}>
                  {el.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionDetails>
        <DataGrid
          rows={sessions || []}
          columns={columns}
          pageSize={25}
          autoHeight
          getRowId={(row) => row.inicio_bloque}
        />
      </AccordionDetails>
    </Accordion>
  );
};
