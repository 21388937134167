import React from "react";
import swal from "sweetalert";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../features/user/userSlice";
import { useState } from "react";
import { selectAuth } from "../../features/user/userSlice";
import { Chips } from "primereact/chips";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutlined } from "@mui/icons-material";
import {
  Fab,
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  ListItem,
  Chip,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  useGetMessagesQuery,
  useUpdateMessageMutation,
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useGetMessagesColumnsQuery,
} from "../../features/api/apiYeapGo";
import { AutoAwesomeMotion, Sensors, SensorWindow } from "@mui/icons-material";
import axios from "axios";
import AutocompleteSelect from "./Autocomplete";

export const Messages = () => {
  const Auth = useSelector(selectAuth);
  const { data, error, isLoading } = useGetMessagesQuery({ Token: Auth });
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [tags, setTags] = useState([]);
  const [updateMessage] = useUpdateMessageMutation();
  const [createMessage] = useCreateMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const { data: columnas } = useGetMessagesColumnsQuery({ Token: Auth });
  let navigate = useNavigate();
  const [sensoresList, setSensoresList] = useState([]);

  const fetchData = async () => {
    try {
      const sensores = await axios.get(
        `${process.env.REACT_APP_API_URL}turnos/sensores2`
      );
      setSensoresList(sensores?.data);
    } catch (error) {
      console.error("Error al obtener los datos de la base de datos:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal(
        "Sin permisos suficientes",
        "Para gestionar Especialistas debe ser administrador",
        "error"
      );
      navigate("/");
    }
  }, [user]);

  const [dataTag, setDataTag] = useState({
    id: "",
    nombre: "",
    sensores: [],
  });

  const handleClose = (action) => {
    setOpen(false);
    setDataTag({
      id: "",
      nombre: "",
      sensores: [],
    });
    setTags([]);
    setUserId(0);
  };

  /* table columns */
  const columns = [
    {
      width: 20,
      sortable: false,
      renderCell: (params) => {
        const eliminarSensor = (e) => {
          e.stopPropagation();
          deleteMessage({ Token: Auth, id: params.row.id }).then(() => {
            swal("Mensaje eliminado correctamente", "", "success");
          });
        };
        return (
          <Button
            variant="text"
            color="secondary"
            startIcon={<DeleteOutlined />}
            onClick={eliminarSensor}
          ></Button>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "sensores",
      headerName: "Sensores",
      width: 200,
    },
    {
      field: "mensaje_inicial",
      headerName: "Mensaje Inicial",
      width: 200,
    },
    {
      field: "items",
      headerName: "Items",
      width: 400,
    },
  ];

  const gestionar = () => {
    if (
      !(
        dataTag.mensaje_inicial.length > 0 &&
        dataTag.sensores.length > 0 &&
        dataTag.items.length > 0
      )
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      if (dataTag?.id === "" || !dataTag.id) {
        console.log(dataTag);
        createMessage({
          Token: Auth,
          body: {
            ...dataTag,
            sensores: dataTag.sensores.join(","),
            items: dataTag.items.join(","),
          },
        })
          .then(() => {
            swal("Mensaje creado correctamente", "", "success");
            setOpen(false);
            /* Funcion para borrar */
            setDataTag({
              id: "",
              mensaje_inicial: "",
              sensores: [],
              items: [],
            });
            setTags([]);
          })
          .catch((e) => {
            swal("No se pudo crear el mensaje", "", "error");
          });
      } else {
        updateMessage({
          Token: Auth,
          id: dataTag.id,
          body: {
            ...dataTag,
            sensores: dataTag.sensores.join(","),
            id_user: userId || 0,
          },
        })
          .then(() => {
            swal("mensaje modificado correctamente", "", "success");
            setOpen(false);
            /* Funcion para borrar */
            setDataTag({
              id: "",
              nombre: "",
              area: "",
              sensores: [],
            });
            setUserId(0);
            setTags([]);
          })
          .catch((e) => {
            swal("No se pudo modificar el mensaje", "", "error");
          });
      }
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          height: 300,
          width: "100%",
        }}
      >
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <DataGrid
              density={"compact"}
              autoHeight
              rows={data || []}
              pageSize={12}
              columns={columns}
              onRowClick={(e) => {
                setOpen(true);
                setDataTag({
                  ...e.row,
                  id: e.row.id,
                  mensaje_inicial: e.row.mensaje_inicial,
                  sensores: e.row.sensores.split(","),
                  items: e.row.items.split(","),
                });
                setUserId(e.row.id_user);
              }}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.id}
            />
          </>
        )}
      </Grid>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="gestionar"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          margin: 0,
          right: 20,
          top: 100,
          left: "auto",
          position: "fixed",
        }}
      >
        Agregar Mensaje
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle>
          <DialogTitle id="scroll-dialog-title">
            GESTIÓN DE MENSAJES
          </DialogTitle>
        </DialogTitle>
        <DialogContent style={{ cursor: "pointer" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2%",
              }}
            >
              <TextField
                fullWidth
                multiline
                required
                value={dataTag.mensaje_inicial}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    mensaje_inicial: e.target.value,
                  })
                }
                label="Mensaje inicial"
                InputProps={{
                  startAdornment: <AutoAwesomeMotion />,
                }}
                variant="outlined"
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                variant="outlined"
                label="Sensores*"
                SelectProps={{
                  multiple: true,
                }}
                value={dataTag?.sensores ?? []}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    sensores: e.target.value,
                  })
                }
              >
                {sensoresList?.map((sensor) => {
                  return (
                    <MenuItem value={sensor.SEN_Sensor} key={sensor.SEN_Sensor}>
                      {sensor.SEN_Sensor}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                variant="outlined"
                label="Items*"
                SelectProps={{
                  multiple: true,
                }}
                value={dataTag?.items ?? []}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    items: e.target.value,
                  })
                }
              >
                {columnas?.map((col) => {
                  return (
                    <MenuItem value={col} key={col}>
                      {col}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>{" "}
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2%",
              }}
            >
              <TextField
                fullWidth
                multiline
                value={
                  (dataTag.mensaje_inicial ?? "") +
                  "\n" +
                  (dataTag?.items?.map((i) => i + ":{valor}").join("\n") ?? "")
                }
                label="Vista previa"
                variant="outlined"
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => gestionar()}
          >
            Aceptar
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
