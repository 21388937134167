import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useGetSTDTimeByUserQuery } from "../../features/api/apiYeapGo";
import Moment from "moment";
import { selectUser } from "../../features/user/userSlice";
const horario = [
  { label: "6am - 2pm", horaInicio: "06", horaFin: "14" },
  { label: "2pm -10pm", horaInicio: "14", horaFin: "22" },
  { label: "10pm - 6am", horaInicio: "22", horaFin: "06" },
];
function secondsToHHMMSS(seconds) {
  // Calcular las horas, minutos y segundos
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let sec = Math.floor(seconds % 60);

  // Añadir ceros a la izquierda si es necesario
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (sec < 10) sec = "0" + sec;

  // Devolver la cadena con el formato hh:mm:ss
  return hours + ":" + minutes + ":" + sec;
}

export const STDTimeByUser = ({ dateRange }) => {
  const [horarioF, setHorarioF] = useState("");
  const { Token } = useSelector(selectUser);
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const { data, isLoading } = useGetSTDTimeByUserQuery({
    Token,
    ...date,
    ...horarioF,
  });
  useEffect(() => {
    if (dateRange?.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);
  const state = {
    series: [
      {
        name: "Tiempo total en minutos",
        data: data?.map((d) => d.tiempo_muerto) || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
          formatter: function (val) {
            return secondsToHHMMSS(parseFloat(val ));
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return secondsToHHMMSS(parseFloat(val));
        },

        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      legend: {
        show: false,
      },

      xaxis: {
        categories: data?.map((d) => d.nombre_usuario) || [],
      },
    },
  };

  return (
    <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Tiempo muerto por usuario
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          select
          fullWidth
          defaultValue=""
          label="Horario"
          onChange={(e) => {
            const selectedHorario = horario.find(
              (h) => h.label === e.target.value
            );
            setHorarioF(selectedHorario);
          }}
        >
          <MenuItem value="" key="0">
            {"Todos los horarios"}
          </MenuItem>
          {horario.map((el) => (
            <MenuItem value={el.label} key={el.label}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      </AccordionDetails>
      <AccordionDetails>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : data.length > 0 ? (
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={"340rem"}
          />
        ) : (
          <>No hay datos disponibles</>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
