import React from "react";
import swal from "sweetalert";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../features/user/userSlice";
import { useState } from "react";
import { selectAuth } from "../../features/user/userSlice";
import { Chips } from "primereact/chips";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DeleteOutlined } from "@mui/icons-material";
import {
  Fab,
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  ListItem,
  Chip,
  Typography,
} from "@mui/material";
import {
  useGetSensoresQuery,
  useUpdateSensorMutation,
  useCreateSensorMutation,
  useDeleteSensorMutation,
} from "../../features/api/apiYeapGo";
import { AutoAwesomeMotion, Sensors, SensorWindow } from "@mui/icons-material";

export const Sensores = () => {
  const Auth = useSelector(selectAuth);
  const { data, error, isLoading } = useGetSensoresQuery({ Token: Auth });
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [updateSensor] = useUpdateSensorMutation();
  const [createSensor] = useCreateSensorMutation();
  const [deletSensor] = useDeleteSensorMutation();
  let navigate = useNavigate();

  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal(
        "Sin permisos suficientes",
        "Para gestionar Tags debe ser Administrador",
        "error"
      );
      navigate("/");
    }
  }, [user]);

  const [dataTag, setDataTag] = useState({
    Id: "",
    Clasification: "",
    Tipo: "",
    Sensor: "",
    Tags: "",
  });

  const handleClose = (action) => {
    setOpen(false);
    setDataTag({
      Id: "",
      Clasification: "",
      Tipo: "",
      Sensor: "",
      Tags: "",
    })
    setTags([]);
  };

  /* table columns */
  const columns = [
    {
      width: 20,
      sortable: false,
      renderCell: (params) => {
        const eliminarSensor = (e) => {
          e.stopPropagation();
          deletSensor({ Token: Auth, id: params.row.SEN_Id })
            .unwrap()
            .then(() => {
              swal("Sensor eliminado correctamente", "", "success");
            });
        };
        return (
          <Button
            variant="text"
            color="secondary"
            startIcon={<DeleteOutlined />}
            onClick={eliminarSensor}
          ></Button>
        );
      },
    },
    {
      field: "SEN_Id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "SEN_Clasification",
      headerName: "Clasificion",
      width: 200,
    },

    {
      field: "SEN_tipoSensor",
      headerName: "Tipo",
      width: 200,
    },
    {
      field: "SEN_Sensor",
      headerName: "Sensor",
      width: 250,
    },
    {
      field: "SEN_Tags",
      headerName: "TAGS",
      width: 1100,
    },
  ];

  const gestionar = () => {
    if (
      dataTag.Clasification === "" ||
      dataTag.Sensor === "" ||
      dataTag.Tipo === "" ||
      tags.length === 0
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      if (dataTag.Id === "") {
        let stringTags = "";
        for (let index = 0; index < tags.length; index++) {
          stringTags = stringTags + tags[index] + ",";
        }
        createSensor({
          Token: Auth,
          body: {
            ...dataTag,
            Tags: stringTags.slice(0, -1),
          },
        })
          .unwrap()
          .then(() => {
            swal("Sensor creado correctamente", "", "success");
            setOpen(false);
            /* Funcion para borrar */
            setDataTag({
              Id: "",
              Clasification: "",
              Tipo: "",
              Sensor: "",
              Tags: "",
            });
            setTags([]);
          })
          .catch((e) => {
            swal("No se pudo crear el Sensor", "", "error");
          });
      } else {
        let stringTags = "";
        for (let index = 0; index < tags.length; index++) {
          stringTags = stringTags + tags[index] + ",";
        }
        updateSensor({
          Token: Auth,
          id: dataTag.Id,
          body: {
            ...dataTag,
            Tags: stringTags.slice(0, -1),
          },
        })
          .unwrap()
          .then(() => {
            swal("Sensor modificado correctamente", "", "success");
            setOpen(false);
            /* Funcion para borrar */
            setDataTag({
              Id: "",
              Clasification: "",
              Tipo: "",
              Sensor: "",
              Tags: "",
            });
            setTags([]);
          })
          .catch((e) => {
            swal("No se pudo modificar el Sensor", "", "error");
          });
      }
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          height: 300,
          width: "100%",
        }}
      >
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <DataGrid
              density={"compact"}
              autoHeight
              rows={data || []}
              pageSize={12}
              columns={columns}
              onRowClick={(e) => {
                setOpen(true);
                setDataTag({
                  Id: e.id,
                  Clasification: e.row.SEN_Clasification,
                  Tipo: e.row.SEN_tipoSensor,
                  Sensor: e.row.SEN_Sensor,
                });
                setTags(e.row.SEN_Tags.split(","));
              }}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.SEN_Id}
            />
          </>
        )}
      </Grid>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="gestionar"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          margin: 0,
          right: 20,
          top: 100,
          left: "auto",
          position: "fixed",
        }}
      >
        Agregar Sensor
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle>
          <DialogTitle id="scroll-dialog-title">GESTIÓN DE SENSORES</DialogTitle>
        </DialogTitle>
        <DialogContent style={{ cursor: "pointer" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2%",
              }}
            >
              <TextField
                fullWidth
                required
                value={dataTag.Clasification}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    Clasification: e.target.value,
                  })
                }
                label="Clasificación"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesomeMotion />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                value={dataTag.Tipo}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    Tipo: e.target.value,
                  })
                }
                label="Tipo"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SensorWindow />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                value={dataTag.Sensor}
                onChange={(e) =>
                  setDataTag({
                    ...dataTag,
                    Sensor: e.target.value,
                  })
                }
                label="Sensor"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Sensors />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Tags</Typography>
              <Chips
                className="custom-chip"
                value={tags}
                onChange={(e) => setTags(e.value)}
                separator=","
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => gestionar()}
          >
            Aceptar
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
