/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import {
  useGetAnalistasQuery,
  useGetEventQuery,
  useGetHistoricoNodoQuery,
  useUpdateEventMutation,
} from "../../features/api/apiYeapGo";
import { selectUser } from "../../features/user/userSlice";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { ArrowBack, BuildOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { DataGrid } from "@mui/x-data-grid";

export const Event = () => {
  let { id } = useParams();
  const { Username, Token } = useSelector(selectUser);
  const navigate = useNavigate();
  const {
    data: dataQuery,
    error,
    isLoading,
  } = useGetEventQuery({ Token, id: id });
  const [updateEvent, { isSuccess, isError }] = useUpdateEventMutation();
  const { data: dataAnalistas, isLoading: isLoadingAnalistas } =
    useGetAnalistasQuery({ Token });

  const [data, setData] = useState({EVE_AfectacionServicio:"NO"});

  const gestionar = () => {
    if (
      !data.EVE_SD_Area ||
      !data.EVE_AfectacionServicio ||
      !data.EVE_SD_AnalistaContactado ||
      !data.EVE_TipoEvento ||
      (!(data.EVE_SD_TicketCreado ?? "") &&
        data.EVE_TipoEvento !== "Informativo")
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      updateEvent({
        Token,
        id,
        body: {
          ...data,
          EVE_Operador: Username,
          EVE_SD_TicketCreado:
            data.EVE_TipoEvento === "Informativo"
              ? "INFORMATIONAL"
              : data.EVE_SD_TicketCreado,
        },
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      swal("Evento gestionado", "", "success");
      navigate("/eventos");
    }
    if (isError) {
      swal("No se ha podido gestionar el evento", "", "error");
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (!isLoading) {
      setData({...dataQuery,EVE_AfectacionServicio:"NO"});
    }
  }, [dataQuery]);

  useEffect(() => {
    if (error) {
      swal("No se encontró el evento", "", "error");
      navigate("/eventos");
    }
  }, [error]);
  const tipoEvento = [
    {
      value: "Ticket",
      label: "Ticket",
    },
    {
      value: "CCT",
      label: "CCT",
    },
    {
      value: "Informativo",
      label: "Informativo",
    },
  ];
  const columns = [
    {
      field: "EVE_FechaRegistro",
      headerName: "FechaEvento",
      width: 220,
    },
    {
      field: "EVE_SD_TicketCreado",
      headerName: "TicketCreado",
      width: 160,
    },
    {
      field: "EVE_Sensor",
      headerName: "EVE_Sensor",
      width: 150,
    },
    {
      field: "EVE_SD_EstadoTicket",
      headerName: "EstadoTicket",
      width: 150,
    },
    ,
    {
      field: "EVE_IPNodo",
      headerName: "EVE_IPNodo",
      width: 180,
    },
    {
      field: "EVE_DescripcionAlarma",
      headerName: "EVE_DescripcionAlarma",
      width: 900,
    },
    {
      field: "ROL",
      headerName: "ROL",
      width: 150,
    },
  ];
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Box sx={{ flexGrow: 1, ml: 1, mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_ID_ActiveAlert"
                value={data.EVE_ID_ActiveAlert || 0}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_ID_ActiveAlert: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_FechaRegistro"
                value={data.EVE_FechaRegistro || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_FechaRegistro: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_FechaHoraAlerta"
                value={data.EVE_FechaHoraAlerta || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_FechaHoraAlerta: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="EVE_DescripcionAlarma"
                value={data.EVE_DescripcionAlarma || ""}
                focused
                color="secondary"
                onChange={(e) => setData(data)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_MensajeAlarma"
                value={data.EVE_MensajeAlarma || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_MensajeAlarma: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                fullWidth
                label="EVE_Severidad"
                value={data.EVE_Severidad || ""}
                focused
                color="secondary"
                onChange={(e) => setData(data)}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_Clasification"
                value={data.EVE_Clasification || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_Clasification: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_TipoSensor"
                value={data.EVE_TipoSensor || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_TipoSensor: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_IDSensor"
                value={data.EVE_IDSensor || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_IDSensor: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_Sensor"
                value={data.EVE_Sensor || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_Sensor: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                disabled
                fullWidth
                label="EVE_IPNodo"
                value={data.EVE_IPNodo || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_IPNodo: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                fullWidth
                value={data.EVE_Caption || ""}
                label="EVE_Caption"
                focused
                color="secondary"
                onChange={(e) => setData(data)}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                fullWidth
                size="small"
                disabled
                value={data.EVE_Ubicacion || ""}
                label="EVE_Ubicacion"
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_Ubicacion: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                fullWidth
                focused
                color="secondary"
                onChange={(e) => setData(data)}
                value={data.EVE_ORN_Ambiente || ""}
                label="EVE_ORN_Ambiente"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                fullWidth
                disabled
                value={data.EVE_SD_EstadoTicket || ""}
                label="EVE_SD_EstadoTicket"
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_SD_EstadoTicket: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                required
                select
                fullWidth
                defaultValue=""
                label="EVE_SD_Area"
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_SD_Area: e.target.value,
                  })
                }
              >
                {[
                  ...new Set(dataAnalistas?.map((a) => a.area_analista_app)),
                ].map((el) => (
                  <MenuItem value={el} key={el}>
                    {el}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6} md={3} lg={3}>
              <TextField
                size="small"
                select
                required
                fullWidth
                defaultValue=""
                label="EVE_SD_AnalistaContactado"
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_SD_AnalistaContactado: e.target.value,
                  })
                }
              >
                {isLoadingAnalistas ? (
                  <MenuItem></MenuItem>
                ) : (
                  dataAnalistas
                    .filter((a) =>
                      a.area_analista_app.includes(data.EVE_SD_Area ?? "")
                    )
                    .map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.nombre_analista_app}>
                          {item.nombre_analista_app}
                        </MenuItem>
                      );
                    })
                )}
              </TextField>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                required
                size="small"
                fullWidth
                select
                value={data.EVE_TipoEvento || ""}
                label="EVE_TipoEvento"
                onChange={(e) =>
                  setData({
                    ...data,
                    EVE_TipoEvento: e.target.value,
                  })
                }
              >
                {tipoEvento.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {data.EVE_TipoEvento !== "Informativo" ? (
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  required
                  id="standard-number"
                  type="number"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.EVE_SD_TicketCreado || ""}
                  label="EVE_SD_TicketCreado"
                  onChange={(e) =>
                    setData({
                      ...data,
                      EVE_SD_TicketCreado: e.target.value,
                    })
                  }
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={6} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.EVE_AfectacionServicio === "SI"}
                    onChange={(e) =>
                      setData({
                        ...data,
                        EVE_AfectacionServicio: e.target.checked ? "SI" : "NO",
                      })
                    }
                  />
                }
                label="Afectación de servicio"
              />
            </Grid>

            {/* {isLoadingNodo ? (
              <></>
            ) : (
              <>
                <Grid item xs={12}>
                  <Divider>
                    <b>
                      <p>Informacion de contexto</p>
                    </b>
                  </Divider>
                </Grid>
                {dataNodo?.open.length > 0 ? (
                  <Grid item xs={12} md={dataNodo?.closed.length > 0 ? 6 : 12}>
                    <Divider>
                      <Chip label={`Open: ${dataNodo.open.length ?? 0}`}></Chip>
                    </Divider>

                    <DataGrid
                      rows={dataNodo?.open || []}
                      columns={columns}
                      pageSize={25}
                      autoHeight
                      getRowId={(row) => row.EVE_SD_TicketCreado}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {dataNodo?.closed.length > 0 ? (
                  <Grid item xs={12} md={dataNodo?.open.length > 0 ? 6 : 12}>
                    <Divider>
                      <Chip
                        label={`Closed: ${dataNodo.closed.length ?? 0}`}
                      ></Chip>
                    </Divider>

                    <DataGrid
                      rows={dataNodo?.closed || []}
                      columns={columns}
                      pageSize={25}
                      autoHeight
                      getRowId={(row) => row.EVE_FechaRegistro}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            )} */}
          </Grid>
          <Fab
            variant="extended"
            color="secondary"
            aria-label="volver"
            onClick={() => {
              navigate("/eventos");
            }}
            style={{
              margin: 0,
              top: "auto",
              right: 200,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
          >
            <ArrowBack sx={{ mr: 1 }} />
            Volver
          </Fab>
          <Fab
            variant="extended"
            color="secondary"
            aria-label="gestionar"
            onClick={gestionar}
            style={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
          >
            <BuildOutlined sx={{ mr: 1 }} />
            Gestionar
          </Fab>
        </Box>
      )}
    </Grid>
  );
};
