/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  useAprobarInterfacesMutation,
  useAprobarNodosMutation,
  useAprobarVolumenesMutation,
  useGetConteoMonitoreoQuery,
  useGetEventsQuery,
  useGetNodosHistoricoQuery,
  useGetNodosQuery,
} from "../../features/api/apiYeapGo";
import * as XLSX from "xlsx";
import { logOut, selectAuth, selectUser } from "../../features/user/userSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { BuildOutlined, Download, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSnackbar } from "notistack";
import { DateRangePicker } from "materialui-daterange-picker";
import Moment from "moment";
export const MonitoreoHistorico = () => {
  const Auth = useSelector(selectAuth);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const {
    data: nodos,
    error,
    isLoading,
  } = useGetNodosHistoricoQuery({ Token: Auth, ...date });

  useEffect(() => {
    if (dateRange?.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);
  const toggle = () => setOpen(!open);

  useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal("Sin permisos suficientes", "", "error");
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (error?.originalStatus === 401) {
      swal("Sesión expirada", "Por favor vuelve a iniciar sesión", "warning");
      dispatch(logOut());
    }
  }, [error]);
  const downloadReport = () => {
    var wb = XLSX.utils.book_new();

    // Hoja para data.Interfaces
    var wsInterfaces = XLSX.utils.json_to_sheet(nodos.interfaces);
    XLSX.utils.book_append_sheet(wb, wsInterfaces, "Interfaces");

    // Hoja para data.Nodos
    var wsNodos = XLSX.utils.json_to_sheet(nodos.nodos);
    XLSX.utils.book_append_sheet(wb, wsNodos, "Nodos");

    // Hoja para data.Volumenes
    var wsVolumenes = XLSX.utils.json_to_sheet(nodos.volumenes);
    XLSX.utils.book_append_sheet(wb, wsVolumenes, "Volumenes");

    XLSX.writeFile(
      wb,
      `Reporte de monitoreo ${date.fechaInicial} - ${date.fechaFinal}.xlsx`
    );
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          {nodos?.interfaces?.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Historial de cambios en interfaces
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <DataGrid
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",

                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",

                      toolbarColumns: "Columnas",

                      toolbarColumnsLabel: "Seleccionar columnas",

                      toolbarDensity: "Densidad",

                      toolbarDensityComfortable: "Comodo",

                      toolbarDensityStandard: "Estandar",

                      toolbarDensityCompact: "Compacto",

                      toolbarFilters: "Filtros",

                      toolbarFiltersLabel: "Ver filtros",

                      toolbarFiltersTooltipHide: "Quitar filtros",

                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={nodos?.interfaces || []}
                    pageSize={25}
                    columns={[
                      {
                        field: "Fecha",
                        headerName: "Fecha",
                        width: 170,
                      },
                      {
                        field: "Id",
                        headerName: "ID",
                        width: 100,
                      },

                      {
                        field: "CaptionInterface",
                        headerName: "CaptionInterface",
                        width: 250,
                      },

                      {
                        field: "NombreNodo",
                        headerName: "NombreNodo",
                        width: 170,
                      },
                      {
                        field: "IPNodo",
                        headerName: "IPNodo",
                        width: 170,
                      },
                      {
                        field: "AmbienteNodo",
                        headerName: "AmbienteNodo",
                        width: 170,
                      },
                      {
                        field: "Estado",
                        headerName: "Estado",
                        width: 170,
                        valueGetter: (params) => {
                          if (params.value === "Desagregado") {
                            return "Retirado";
                          } else {
                            return params.value;
                          }
                        },
                      },
                      {
                        field: "Aprobado",
                        headerName: "Aprobado",
                        width: 170,
                      },
                      {
                        field: "Usuario",
                        headerName: "Usuario",
                        width: 170,
                      },
                      {
                        field: "Observacion",
                        headerName: "Observación",
                        width: 170,
                      },
                      {
                        field: "Ticket",
                        headerName: "Ticket",
                        width: 170,
                      },
                      {
                        field: "Fechadeactualizacion",
                        headerName: "Actualizado",
                        width: 170,
                      },
                      {
                        field: "FechaAprobacion",
                        headerName: "FechaAprobacion",
                        width: 170,
                      },
                    ]}
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.Id}
                    getRowClassName={(params) => {
                      const estado = params.row.Estado; // Asegúrate de reemplazar "Estado" con la propiedad correcta en tu objeto de fila
                      return estado === "Desagregado"
                        ? "row-desagregado"
                        : estado === "Agregado"
                        ? "row-agregado"
                        : "";
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <></>
          )}
          {nodos?.nodos?.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Historial de cambios en nodos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <DataGrid
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",

                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",

                      toolbarColumns: "Columnas",

                      toolbarColumnsLabel: "Seleccionar columnas",

                      toolbarDensity: "Densidad",

                      toolbarDensityComfortable: "Comodo",

                      toolbarDensityStandard: "Estandar",

                      toolbarDensityCompact: "Compacto",

                      toolbarFilters: "Filtros",

                      toolbarFiltersLabel: "Ver filtros",

                      toolbarFiltersTooltipHide: "Quitar filtros",

                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={nodos?.nodos || []}
                    pageSize={25}
                    columns={[
                      {
                        field: "Fecha",
                        headerName: "Fecha",
                        width: 170,
                      },
                      {
                        field: "Id",
                        headerName: "ID",
                        width: 100,
                      },
                      {
                        field: "Caption",
                        headerName: "NombreNodo",
                        width: 250,
                      },

                      {
                        field: "IP_Address",
                        headerName: "IPNodo",
                        width: 170,
                      },
                      {
                        field: "Ambiente",
                        headerName: "AmbienteNodo",
                        width: 170,
                      },
                      {
                        field: "Estado",
                        headerName: "Estado",
                        width: 170,
                        valueGetter: (params) => {
                          if (params.value === "Desagregado") {
                            return "Retirado";
                          } else {
                            return params.value;
                          }
                        },
                      },
                      {
                        field: "Aprobado",
                        headerName: "Aprobado",
                        width: 170,
                      },
                      {
                        field: "Usuario",
                        headerName: "Usuario",
                        width: 170,
                      },
                      {
                        field: "Observacion",
                        headerName: "Observación",
                        width: 170,
                      },
                      {
                        field: "Ticket",
                        headerName: "Ticket",
                        width: 170,
                      },
                      {
                        field: "Fechadeactualizacion",
                        headerName: "Actualizado",
                        width: 170,
                      },
                      {
                        field: "FechaAprobacion",
                        headerName: "FechaAprobacion",
                        width: 170,
                      },
                    ]}
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.Id}
                    getRowClassName={(params) => {
                      const estado = params.row.Estado; // Asegúrate de reemplazar "Estado" con la propiedad correcta en tu objeto de fila
                      return estado === "Desagregado"
                        ? "row-desagregado"
                        : estado === "Agregado"
                        ? "row-agregado"
                        : "";
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <></>
          )}

          {nodos?.volumenes?.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Historial de cambios en volúmenes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <DataGrid
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",

                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",

                      toolbarColumns: "Columnas",

                      toolbarColumnsLabel: "Seleccionar columnas",

                      toolbarDensity: "Densidad",

                      toolbarDensityComfortable: "Comodo",

                      toolbarDensityStandard: "Estandar",

                      toolbarDensityCompact: "Compacto",

                      toolbarFilters: "Filtros",

                      toolbarFiltersLabel: "Ver filtros",

                      toolbarFiltersTooltipHide: "Quitar filtros",

                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={nodos?.volumenes || []}
                    pageSize={25}
                    columns={[
                      {
                        field: "Fecha",
                        headerName: "Fecha",
                        width: 170,
                      },
                      {
                        field: "Id",
                        headerName: "ID",
                        width: 100,
                      },
                      {
                        field: "AmbienteNodo",
                        headerName: "AmbienteNodo",
                        width: 170,
                      },
                      {
                        field: "CaptionVolumen",
                        headerName: "CaptionVolumen",
                        width: 250,
                      },
                      {
                        field: "IPNodo",
                        headerName: "IPNodo",
                        width: 170,
                      },
                      {
                        field: "NombreNodo",
                        headerName: "NombreNodo",
                        width: 170,
                      },
                      {
                        field: "Estado",
                        headerName: "Estado",
                        width: 170,
                        valueGetter: (params) => {
                          if (params.value === "Desagregado") {
                            return "Retirado";
                          } else {
                            return params.value;
                          }
                        },
                      },
                      {
                        field: "Aprobado",
                        headerName: "Aprobado",
                        width: 170,
                      },
                      {
                        field: "Usuario",
                        headerName: "Usuario",
                        width: 170,
                      },
                      {
                        field: "Observacion",
                        headerName: "Observación",
                        width: 170,
                      },
                      {
                        field: "Ticket",
                        headerName: "Ticket",
                        width: 170,
                      },
                      {
                        field: "Fechadeactualizacion",
                        headerName: "Actualizado",
                        width: 170,
                      },
                      {
                        field: "FechaAprobacion",
                        headerName: "FechaAprobacion",
                        width: 170,
                      },
                    ]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.Id}
                    getRowClassName={(params) => {
                      const estado = params.row.Estado; // Asegúrate de reemplazar "Estado" con la propiedad correcta en tu objeto de fila
                      return estado === "Desagregado"
                        ? "row-desagregado"
                        : estado === "Agregado"
                        ? "row-agregado"
                        : "";
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}{" "}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="volver"
        onClick={toggle}
        style={{
          margin: 0,
          top: "auto",
          right: 250,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        Establecer rango de fecha
      </Fab>{" "}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Descargar reporte"
        style={{
          margin: 0,
          top: "auto",
          right: 10,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
        onClick={downloadReport}
      >
        <Download sx={{ mr: 1 }} />
        Descargar reporte
      </Fab>
      <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Selecciona el rango de fecha"}
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
