import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import {
  EventAvailable,
  ExpandLess,
  ExpandMore,
  Mail,
  ReceiptLongOutlined,
  Sensors,
  FactCheck,
  HowToReg,
  ManageAccounts,
  WhatsApp,
  MarkEmailRead,
} from "@mui/icons-material";
import { QueryStats } from "@mui/icons-material";
import { Collapse, List } from "@mui/material";

const ListItem = ({ text, link, Icon }) => {
  const navigate = useNavigate();
  return (
    <ListItemButton onClick={() => navigate(link)}>
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText primary={text} style={{ whiteSpace: "pre-wrap" }} />
    </ListItemButton>
  );
};

const ListItemWithSubItems = ({ text, link, Icon, subItems }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={
          subItems
            ? handleCollapse
            : () => {
                if (link.includes("http")) {
                  window.location.href = link;
                } else {
                  navigate(link);
                }
              }
        }
      >
        <ListItemIcon>{Icon}</ListItemIcon>
        <ListItemText
          primary={<span style={{ whiteSpace: "pre-wrap" }}>{text}</span>}
        />
        {subItems && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {subItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((subItem) => (
              <ListItem
                key={subItem.text}
                text={subItem.text}
                link={subItem.link}
                Icon={subItem.Icon}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export const MainListItems = () => {
  const user = useSelector(selectUser);
  return (
    <>
      {user.Rol !== "Administrador" ? (
        <>
         
          <ListItem
            text="Gestión de eventos"
            link="eventos"
            Icon={<EventAvailable />}
          ></ListItem>
          <ListItem
            text="Gestión de sensores"
            link="sensores"
            Icon={<Sensors />}
          ></ListItem>
       
          <ListItemWithSubItems
            text="Estadisticas"
            Icon={<BarChartIcon />}
            subItems={[
              
              {
                text: "Estadisticas eventos",
                link: "estadisticas",
                Icon: <BarChartIcon />,
              },
            ]}
          />
          <ListItemWithSubItems
            text="Reportes"
            Icon={<ReceiptLongOutlined />}
            subItems={[
              {
                text: "Reporte de eventos",
                link: "reporte",
                Icon: <ReceiptLongOutlined />,
              },
            
            ]}
          />
        </>
      ) : (
        <>
          <ListItem
            text="Gestión de usuarios"
            link="usuarios"
            Icon={<PeopleIcon></PeopleIcon>}
          ></ListItem>
          <ListItem
            text="Gestión de eventos"
            link="eventos"
            Icon={<EventAvailable />}
          ></ListItem>
          <ListItem
            text="Gestión de sensores"
            link="sensores"
            Icon={<Sensors />}
          ></ListItem>
          <ListItemWithSubItems
            text="Monitoreo de inventarios"
            Icon={<FactCheck />}
            subItems={[
              {
                text: "Gestión de inventario",
                link: "nodos",
                Icon: <FactCheck />,
              },

              {
                text: "Correos a notificar",
                link: "mails",
                Icon: <MarkEmailRead />,
              },
            ]}
          />
          <ListItemWithSubItems
            text="Notificaciones RPA"
            Icon={<EventNoteIcon />}
            subItems={[
              {
                text: "Usuarios",
                link: "usuariosturno",
                Icon: <ManageAccounts />,
              },
              {
                text: "Calendario",
                link: "turnos",
                Icon: <EventNoteIcon />,
              },
              {
                text: "Especialistas",
                link: "especialistasturno",
                Icon: <HowToReg />,
              },
              {
                text: "Mensajes",
                link: "mensajes",
                Icon: <WhatsApp />,
              },
            ]}
          />{" "}
          <ListItemWithSubItems
            text="Estadisticas"
            Icon={<BarChartIcon />}
            subItems={[
              {
                text: "Estadisticas analistas",
                link: "estadisticas/Analistas",
                Icon: <QueryStats />,
              },
              {
                text: "Estadisticas eventos",
                link: "estadisticas",
                Icon: <BarChartIcon />,
              },
              {
                text: "Estadisticas de inventario",
                link: "nodos/reports",
                Icon: <BarChartIcon />,
              },
            ]}
          />
          <ListItemWithSubItems
            text="Reportes"
            Icon={<ReceiptLongOutlined />}
            subItems={[
              {
                text: "Reporte de eventos",
                link: "reporte",
                Icon: <ReceiptLongOutlined />,
              },
              {
                text: "Reporte de inventario",
                link: "nodos/historial",
                Icon: <ReceiptLongOutlined />,
              },
            ]}
          />
        </>
      )}

      {/* <ListItem
        text="Estadisticas eventos"
        link="estadisticas"
        Icon={<BarChartIcon />}
      ></ListItem> */}
      {/* {user.Rol !== "Administrador" ? (
        <></>
      ) : (
        <>
          <ListItem
            text="Estadisticas analistas"
            link="estadisticas/Analistas"
            Icon={<QueryStats />}
          ></ListItem>
        </>
      )} */}
    </>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);
