/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  useAprobarInterfacesMutation,
  useAprobarNodosMutation,
  useAprobarVolumenesMutation,
  useGetConteoMonitoreoQuery,
  useGetEventsQuery,
  useGetNodosQuery,
} from "../../features/api/apiYeapGo";
import { logOut, selectAuth, selectUser } from "../../features/user/userSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { BuildOutlined, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSnackbar } from "notistack";

export const Monitoreo = () => {
  const Auth = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(selectUser);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [eventsID, setEventsID] = useState([]);
  const [interfacesID, setInterfacesID] = useState([]);
  const [nodosID, setNodosID] = useState([]);
  const [volumenesID, setVolumenesID] = useState([]);
  const [observacion, setObservacion] = useState("");
  const [ticket, setTicket] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aprobarInterfaces] = useAprobarInterfacesMutation();
  const [aprobarNodos] = useAprobarNodosMutation();
  const [aprobarVolumenes] = useAprobarVolumenesMutation();
  const {
    data: nodos,
    error,
    isLoading,
  } = useGetNodosQuery(
    { Token: Auth },
    {
      pollingInterval: 1000 * 5,
    }
  );

  const { data: conteo, isLoading: isLoadingConteo } =
    useGetConteoMonitoreoQuery(
      { Token: Auth },
      {
        pollingInterval: 1000 * 5,
      }
    );

  useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal("Sin permisos suficientes", "", "error");
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (error?.originalStatus === 401) {
      swal("Sesión expirada", "Por favor vuelve a iniciar sesión", "warning");
      dispatch(logOut());
    }
  }, [error]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          {nodos?.interfaces?.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Cambios sin aprobar en interfaces
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <DataGrid
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",

                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",

                      toolbarColumns: "Columnas",

                      toolbarColumnsLabel: "Seleccionar columnas",

                      toolbarDensity: "Densidad",

                      toolbarDensityComfortable: "Comodo",

                      toolbarDensityStandard: "Estandar",

                      toolbarDensityCompact: "Compacto",

                      toolbarFilters: "Filtros",

                      toolbarFiltersLabel: "Ver filtros",

                      toolbarFiltersTooltipHide: "Quitar filtros",

                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={nodos?.interfaces || []}
                    pageSize={25}
                    columns={[
                      {
                        field: "IdInterface",
                        headerName: "ID",
                        width: 100,
                      },
                      {
                        field: "CaptionInterface",
                        headerName: "CaptionInterface",
                        width: 250,
                      },

                      {
                        field: "NombreNodo",
                        headerName: "NombreNodo",
                        width: 170,
                      },
                      {
                        field: "IPNodo",
                        headerName: "IPNodo",
                        width: 170,
                      },
                      {
                        field: "AmbienteNodo",
                        headerName: "AmbienteNodo",
                        width: 170,
                      },
                      {
                        field: "Estado",
                        headerName: "Estado",
                        width: 170,
                        valueGetter: (params) => {
                          if (params.value === "Desagregado") {
                            return "Retirado";
                          } else {
                            return params.value;
                          }
                        },
                      },
                    ]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.IdInterface}
                    onSelectionModelChange={(e) => setInterfacesID(e)}
                    getRowClassName={(params) => {
                      const estado = params.row.Estado; // Asegúrate de reemplazar "Estado" con la propiedad correcta en tu objeto de fila
                      return estado === "Desagregado"
                        ? "row-desagregado"
                        : estado === "Agregado"
                        ? "row-agregado"
                        : "";
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <></>
          )}
          {nodos?.nodos?.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Cambios sin aprobar en nodos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <DataGrid
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",

                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",

                      toolbarColumns: "Columnas",

                      toolbarColumnsLabel: "Seleccionar columnas",

                      toolbarDensity: "Densidad",

                      toolbarDensityComfortable: "Comodo",

                      toolbarDensityStandard: "Estandar",

                      toolbarDensityCompact: "Compacto",

                      toolbarFilters: "Filtros",

                      toolbarFiltersLabel: "Ver filtros",

                      toolbarFiltersTooltipHide: "Quitar filtros",

                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={nodos?.nodos || []}
                    pageSize={25}
                    columns={[
                      {
                        field: "NodeID",
                        headerName: "ID",
                        width: 100,
                      },
                      {
                        field: "Caption",
                        headerName: "NombreNodo",
                        width: 250,
                      },

                      {
                        field: "IP_Address",
                        headerName: "IPNodo",
                        width: 170,
                      },
                      {
                        field: "Ambiente",
                        headerName: "AmbienteNodo",
                        width: 170,
                      },
                      {
                        field: "Estado",
                        headerName: "Estado",
                        width: 170,
                        valueGetter: (params) => {
                          if (params.value === "Desagregado") {
                            return "Retirado";
                          } else {
                            return params.value;
                          }
                        },
                      },
                    ]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.NodeID}
                    onSelectionModelChange={(e) => setNodosID(e)}
                    getRowClassName={(params) => {
                      const estado = params.row.Estado; // Asegúrate de reemplazar "Estado" con la propiedad correcta en tu objeto de fila
                      return estado === "Desagregado"
                        ? "row-desagregado"
                        : estado === "Agregado"
                        ? "row-agregado"
                        : "";
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <></>
          )}

          {nodos?.volumenes?.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    Cambios sin aprobar en volúmenes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <DataGrid
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",

                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",

                      toolbarColumns: "Columnas",

                      toolbarColumnsLabel: "Seleccionar columnas",

                      toolbarDensity: "Densidad",

                      toolbarDensityComfortable: "Comodo",

                      toolbarDensityStandard: "Estandar",

                      toolbarDensityCompact: "Compacto",

                      toolbarFilters: "Filtros",

                      toolbarFiltersLabel: "Ver filtros",

                      toolbarFiltersTooltipHide: "Quitar filtros",

                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                    autoHeight
                    getRowHeight={() => "auto"}
                    rows={nodos?.volumenes || []}
                    pageSize={25}
                    columns={[
                      {
                        field: "IdVolumen",
                        headerName: "ID",
                        width: 100,
                      },
                      {
                        field: "AmbienteNodo",
                        headerName: "AmbienteNodo",
                        width: 170,
                      },
                      {
                        field: "CaptionVolumen",
                        headerName: "CaptionVolumen",
                        width: 250,
                      },
                      {
                        field: "IPNodo",
                        headerName: "IPNodo",
                        width: 170,
                      },
                      {
                        field: "NombreNodo",
                        headerName: "NombreNodo",
                        width: 170,
                      },
                      {
                        field: "Estado",
                        headerName: "Estado",
                        width: 170,
                        valueGetter: (params) => {
                          if (params.value === "Desagregado") {
                            return "Retirado";
                          } else {
                            return params.value;
                          }
                        },
                      },
                    ]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.IdVolumen}
                    onSelectionModelChange={(e) => setVolumenesID(e)}
                    getRowClassName={(params) => {
                      const estado = params.row.Estado; // Asegúrate de reemplazar "Estado" con la propiedad correcta en tu objeto de fila
                      return estado === "Desagregado"
                        ? "row-desagregado"
                        : estado === "Agregado"
                        ? "row-agregado"
                        : "";
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="gestionar"
        onClick={() => {
          setDialogOpen(true);
        }}
        style={{
          margin: 0,
          top: "auto",
          left: 250,
          bottom: 20,
          position: "fixed",
        }}
      >
        <BuildOutlined sx={{ mr: 1 }} />
        Aprobar
      </Fab>

      {!isLoadingConteo ? (
        <>
          {" "}
          <Fab
            variant="extended"
            color="info"
            aria-label="Nodos"
            style={{
              margin: 0,
              right: 20,
              top: 100,
              left: "auto",
              position: "fixed",
            }}
          >
            Nodos:
            {conteo[0].Total_Nodos}
          </Fab>
          <Fab
            variant="extended"
            color="info"
            aria-label="Interfaces"
            style={{
              margin: 0,
              right: 150,
              top: 100,
              left: "auto",
              position: "fixed",
            }}
          >
            Interfaces:
            {conteo[0].Total_Interfaces}
          </Fab>
          <Fab
            variant="extended"
            color="info"
            aria-label="Volúmenes"
            style={{
              margin: 0,
              right: 320,
              top: 100,
              left: "auto",
              position: "fixed",
            }}
          >
            Volúmenes:
            {conteo[0].Total_Volumenes}
          </Fab>
        </>
      ) : (
        <></>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="md" // Ancho máximo del dialog para que quepa el contenido
      >
        <DialogTitle>Aprobar cambios</DialogTitle>
        <DialogContent>
          <TextField
            label="Ticket* "
            value={ticket}
            onChange={(e) => {
              setTicket(e.target.value);
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Observación"
            multiline
            rows={4}
            value={observacion}
            onChange={(e) => {
              setObservacion(e.target.value);
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancelar</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //let regex = /^[a-zA-Z0-9]+$/;
              let regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]+$/;

              // Prueba si la variable cumple con la expresión regular

              if (!ticket) {
                swal({
                  title: "Debe llenar el ticket.",
                  icon: "error",
                });
                return;
              }
              if (!regex.test(ticket)) {
                swal({
                  title: "El ticket no puede contener caracteres especiales.",
                  icon: "error",
                });
                return;
              }
              if (interfacesID.length > 0) {
                aprobarInterfaces({
                  Token: Auth,
                  body: {
                    ids: interfacesID,
                    estados: interfacesID.map(
                      (i) =>
                        nodos?.interfaces.find((ni) => ni.IdInterface === i)[
                          "Estado"
                        ]
                    ),
                    ticket,
                    observacion,
                    usuario: user.id,
                  },
                })
                  .unwrap()
                  .then(() => {
                    setDialogOpen(false);
                    setObservacion("");
                    setTicket("");
                    enqueueSnackbar(
                      `Cambios en interfaces aprobados correctamente`,
                      { variant: "success" }
                    );
                  })
                  .catch((error) => {
                    enqueueSnackbar(
                      `Hubo un error al intentar aprobar los cambios en interfaces`,
                      { variant: "error" }
                    );
                  });
              }
              if (nodosID.length > 0) {
                aprobarNodos({
                  Token: Auth,
                  body: {
                    ids: nodosID,
                    estados: nodosID.map(
                      (i) =>
                        nodos?.nodos.find((ni) => ni.NodeID === i)["Estado"]
                    ),
                    ticket,
                    observacion,
                    usuario: user.id,
                  },
                })
                  .unwrap()
                  .then(() => {
                    setDialogOpen(false);
                    setObservacion("");
                    setTicket("");
                    enqueueSnackbar(
                      `Cambios en nodos aprobados correctamente`,
                      { variant: "success" }
                    );
                  })
                  .catch((error) => {
                    enqueueSnackbar(
                      `Hubo un error al intentar aprobar los cambios en nodos`,
                      { variant: "error" }
                    );
                  });
              }
              if (volumenesID.length > 0) {
                aprobarVolumenes({
                  Token: Auth,
                  body: {
                    ids: volumenesID,
                    estados: volumenesID.map(
                      (i) =>
                        nodos?.volumenes.find((ni) => ni.IdVolumen === i)[
                          "Estado"
                        ]
                    ),
                    ticket,
                    observacion,
                    usuario: user.id,
                  },
                })
                  .unwrap()
                  .then(() => {
                    setDialogOpen(false);
                    setObservacion("");
                    setTicket("");
                    enqueueSnackbar(
                      `Cambios en volúmenes aprobados correctamente`,
                      { variant: "success" }
                    );
                  })
                  .catch((error) => {
                    enqueueSnackbar(
                      `Hubo un error al intentar aprobar los cambios en volúmenes`,
                      { variant: "error" }
                    );
                  });
              }
            }}
          >
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
