import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  useGetNodosStatsMonthQuery,
  useGetNodosStatsQuery,
  useGetSeveridadStatisticsQuery,
} from "../../features/api/apiYeapGo";
import Moment from "moment";
import { selectUser } from "../../features/user/userSlice";

export const STDesagregadosMonth = ({ dateRange,ambiente }) => {
  const { Token } = useSelector(selectUser);
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const { data, isLoading } = useGetNodosStatsMonthQuery({
    Token,
    ...date,ambiente
  });

  useEffect(() => {
    if (dateRange?.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);
  const state = {
    series: [
      {
        name: "Nodos",
        data: data?.map((item) => item.Nodos.Desagregados),
      },
      {
        name: "Interfaces",
        data: data?.map((item) => item.Interfaces.Desagregados),
      },
      {
        name: "Volúmenes",
        data: data?.map((item) => item.Volumenes.Desagregados),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: { categories: data?.map((item) => item.Mes) },
      yaxis: {
        title: {
          text: "Cantidad",
        },
      },
      fill: {
        opacity: 1,
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Nodos retirados por mes
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : data.length > 0 ? (
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={"340rem"}
          />
        ) : (
          <>No hay datos disponibles</>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
