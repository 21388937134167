import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const AutocompleteSelect = ({ options, label, data, setData }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (data) {
      const option = options.find((option) => option.id === data);
      setSelectedOption(option);
    }
  }, [data, options]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (newValue) {
      const filtered = options.filter(
        (option) => option.id === newValue.inputValue
      );
      setFilteredOptions(filtered);
      setData(newValue.id);
    } else {
      setFilteredOptions(options);
      setData(null);
    }
  };

  return (
    <Autocomplete
    fullWidth
      options={filteredOptions}
      getOptionLabel={(option) => option.completename}
      value={selectedOption}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField fullWidth {...params} label={label} variant="outlined" fullWidth />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.completename}
        </li>
      )}
    />
  );
};

export default AutocompleteSelect;
